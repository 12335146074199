* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

@font-face {
  font-family: "ClassicGrotesquePro";
  src: url("./fonts/ClassicGrotesqueW05-Bold.woff2") format("woff2"), url("./fonts/ClassicGrotesqueW05-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "ClassicGrotesquePro";
  src: url("./fonts/ClassicGrotesquePro-Regular.woff2") format("woff2"), url("./fonts/ClassicGrotesquePro-Regular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "ClassicGrotesquePro";
  src: url("./fonts/ClassicGrotesqueW05-SemiBd.woff2") format("woff2"), url("./fonts/ClassicGrotesqueW05-SemiBd.woff") format("woff");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Druk Web";
  src: url("./fonts/Druk-Medium-Web.woff2") format("woff2"), url("./fonts/Druk-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
    font-family: "Mote";
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/mote-semibold.woff) format("woff")
}

@font-face {
    font-family: "Mote";
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/mote-regular.woff) format("woff")
}

p {
	margin-bottom: 0 !important;
}

.calculator-container {
	max-width: 1200px;
	min-height: 661px;
	margin: 0 auto;
	font-family: 'ClassicGrotesquePro', sans-serif;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.calculator-form_container {
	display: flex;
	flex-direction: column;
	gap: 21px;
}

.input-group {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.inp {
	position: relative;
	width: 100%;
}

.input-group label {
	color: #333333;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.5 !important;
}

.input-group select {
	width: 100%;
	padding: 11px 10px !important;
	font-size: 16px;
	height: 44px;
	outline: none;
	font-weight: 500;
	color: #333333;
	cursor: pointer;
	position: relative;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	z-index: 1;
	background: transparent;
	border: 1px solid #AFAFAF;
	border-radius: 0;
}

.input-group .select-arrow:after {
	content: '';
	cursor: pointer;
	display: block;
	height: 44px;
	background: url('data:image/svg+xml,<svg fill="%23333333" width="24" height="16" viewBox="24 16 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg"><path d="M128,188a11.96187,11.96187,0,0,1-8.48535-3.51465l-80-80a12.0001,12.0001,0,0,1,16.9707-16.9707L128,159.0293l71.51465-71.51465a12.0001,12.0001,0,0,1,16.9707,16.9707l-80,80A11.96187,11.96187,0,0,1,128,188Z"/></svg>') no-repeat right 0px top 50%;
	z-index: 0;
}

.calculator-form_heading {
	font-size: 48px;
	font-family: 'Druk Web', TimesNewRoman, 'Times New Roman', Times, Baskerville, Georgia, serif;
	line-height: 70px;
	font-weight: 500;
	margin-bottom: 30px;
}

.inp input {
	width: 100%;
	padding: 11px 10px !important;
	font-size: 16px;
	outline: none;
	font-weight: 400;
	height: 44px;
	color: #333333;
	border: 1px solid #AFAFAF;
	border-radius: 0;
}

.inp input.invalid, .inp select.invalid {
	border-color: #AE2727;
	border-style: solid;
	color: #AE2727;
}

.inp span {
	position: absolute;
	right: 10px;
	font-weight: 500;
	color: #333333;
	font-size: 20px;
    height: 44px;
    width: 24px;
    line-height: 44px;
	text-align: center
}

.tooltip-element {
	height: 44px;
	line-height: 44px;
	width: 24px;
	margin-left: -10px;
    text-align: center;
}

.tooltip-element svg {
	width: 16px;
	height: 16px;
}

.tooltip-element .react-tooltip {
	background-color: #fff;
	color: #000;
    border: 0;
    box-shadow: 0 2px 12px rgba(0,0,0,.2);
    cursor: default;
	z-index:9999;
	max-width: 300px;
	font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    min-width: 250px;
    padding: 16px;
}

.tooltip-element .react-tooltip-arrow {
	display: none;
}

.hidden {
	display: none;
}

.error-message {
	display: none;
}

.inp.invalid .error-message, .interest_inp.invalid .error-message {
	display: block;
	margin-top: 10px;
	color: #AE2727;
}

.inp-button {
	width: 100%;
	display: flex;
	gap: 13px;
	justify-content: space-between;
}

.form-button {
	border: none;
	padding: 11px 14px;
	font-size: 16px;
	height: 44px;
	font-weight: 600;
	transition: 0.3s;
	margin-top: 20px;
	font-family: inherit;
	width: max-content;
}

.form-button:hover {
	transition: 0.3s;
	cursor: pointer;
}

.interest_inp {
    display: grid;
    column-gap: 20px;
    grid-template-columns: auto auto 24px;
    grid-template-rows: auto auto;
}

/* --------------- results -------------------- */

.results-container {
	display: flex;
	flex-direction: column;
	margin-top: 40px;
	background: #F5F5F5;
	padding: 15px;
}

.results-container_elm {
	display: flex;
	align-items: center;
	gap: 60px;
	row-gap: 30px;
	flex-wrap: wrap;
}

.results-container_name {
	font-size: 30px;
	font-weight: 700;
}

.results-container_item {
	display: flex;
	flex-direction: column;
	gap: 20px;
	min-width: 100px;
}

.results-container_item p:nth-child(1) {
	font-size: 16px;
	font-weight: 600;
	color: #000000;
}

.results-container_item p:nth-child(2) {
	font-size: 16px;
	font-weight: 400;
	padding: 10px;
	color: #666666;
	border: 1px solid #AFAFAF;
	width: max-content;
}

.results-container_item p.grey-result {
	color: #ffffff;
	background: #666666;
	border-color: #666666;
}

.results-container table {
	border-collapse: collapse;
	width: 100%;
}

.results-container table tr th {
	text-align: left;
	font-weight: 700;
	position: sticky;
	top: 0;
	background: #F5F5F5;
}

.results-container table tr th, .results-container table tr td {
	padding: 5px 10px;
	font-size: 16px;
	text-wrap: nowrap;
	border-bottom: 1px solid rgba(0,0,0,10%);
}

.results-container table tfoot td {
	color: #666666;
	padding: 20px 0 0;
	text-align: right;
	border-bottom: 0;
}

.results-container table tr th:nth-child(1), .results-container table tr td:nth-child(1) {
	padding-left: 0;
}

.results-container table tr th:last-child, .results-container table tr td:last-child {
	padding-right: 0;
}

.results-container .rs-panel-btn {
	padding: 0;
	color: #666666;
	justify-content: center;
}

.results-container .rs-panel-collapsible > .rs-panel-header {
	padding: 0 0 30px;
}

.results-container .rs-panel-btn .rs-panel-title {
	flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    margin-right: 10px;
}

.results-container .rs-panel-btn:focus,
.results-container .rs-panel-btn:hover {
	background-color: transparent !important;
	cursor: pointer;
}

.results-container .rs-panel-btn svg {
	transition: transform .2s ease 0s, -webkit-transform .2s ease 0s;
}

.results-container .rs-panel-in .rs-panel-btn svg {
	transform: rotate(180deg);
}

.chart-container {
	width: 100%;
	height: 400px;
	position: relative;
	margin-top: 30px;
	margin-bottom: 30px;
}

@media only screen and (max-width: 990px) {
	.results-container table {
		display: block;
		overflow: auto;
	}
}

@media only screen and (max-width: 768px) {
	.results-container {
		width: 100%;
	}
	.calculator-form_container {
		width: 100%;
	}
	.interest_inp {
		row-gap: 20px;
	}
	.interest_inp .inp.etf {
		grid-column: 1 / span 3;
		grid-row: 1;
	}
	.interest_inp .inp.interest {
		grid-column: 1 / span 2;
		grid-row: 2;
	}
	.interest_inp .tooltip-element {
		grid-column: 3;
		grid-row: 2;
	}
	.results-container_elm .results-container_item:nth-child(1) {
		width: 100%;
	}
	.results-container table tr th, .results-container table tr td {
		font-size: 12px;
		text-wrap: nowrap;
	}
}

input:disabled {
	color: #777777a6;
}